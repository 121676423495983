<template>
    <section class="c-login">
        <div class="c-login__body">

            <app-intro>
                <h1>Wachtwoord vergeten?</h1>
                <h3>Vul uw e-mailadres in en u krijgt een e-mail met instructies</h3>
            </app-intro>

            <forgot-password-reset-form/>

        </div>
    </section>
</template>

<script>
    import AppIntro from '../../components/app/AppIntro.vue';
    import ForgotPasswordResetForm from '../../components/forms/ForgotPasswordResetForm.vue';

    export default {
        components: { ForgotPasswordResetForm, AppIntro },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_essentials.scss";

    .c-login {
        display: grid;
        height: 100%;
        -ms-grid-columns: 1fr;

        &__body {
            align-self: center;
            justify-self: center;
            width: 85%;

            @include media-breakpoint-up(md) {
                width: 50%;
            }
        }
    }
</style>
