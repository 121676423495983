<template>
    <form @submit.prevent="handleSubmit" :class="'form' + (theme !== '' ? ' form--' + theme : '')" novalidate>
        <div class="form-fieldset">
            <div class="form-group" :class="{ 'has-error': submitted && $v.form.email.$error }">
                <label class="form-label" for="login-email">Email</label>
                <input type="email" v-model="form.email" id="login-email" name="email" class="form-control" placeholder="Vul hier uw email in" />
                <div v-if="submitted && $v.form.email.$error" class="form-validation">
                    <span v-if="!$v.form.email.required">Email is verplicht</span>
                    <span v-if="!$v.form.email.email">Email is ongeldig</span>
                </div>
            </div>
        </div>

        <div class="form-fieldset">
            <button type="submit" class="[ c-btn c-btn--primary ] form-submit-btn" :disabled="status.loggingIn">
                <svg xmlns="http://www.w3.org/2000/svg" width="21.422" height="21.422" viewBox="0 0 21.422 21.422">
                    <g fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" transform="translate(.5 .5)">
                        <path d="M4.322 10.211h10.995"></path>
                        <circle cx="10.211" cy="10.211" r="10.211"></circle>
                        <path d="M10.21 5.106l5.106 5.106-5.106 5.106"></path>
                    </g>
                </svg>
                <span v-show="status.sending">Bezig met versturen...</span>
                <span v-show="!status.sending">Verstuur</span>
            </button>
        </div>
    </form>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import { required, minLength, sameAs } from 'vuelidate/lib/validators';
    import normalizeFormData from '../../helpers/normalizeFormData';

    export default {
        name: 'ForgotPasswordResetForm',
        data () {
            return {
                form: {
                    password: '',
                    passwordRepeat: '',
                },
                submitted: false,
            };
        },
        props: {
            theme: {
                type: String,
                default: 'light',
            },
        },
        validations: {
            form: {
                password: { required, minLength: minLength(6) },
                passwordRepeat: { required, sameAsPassword: sameAs('password') },
            },
        },
        computed: {
            ...mapState('account', ['status']),
        },
        methods: {
            ...mapActions('account', ['passwordReset']),
            async handleSubmit (e) {
                this.submitted = true;

                // validate the form
                this.$v.form.$touch();

                // if its still pending or an error is returned do not submit
                if (this.$v.form.$pending || this.$v.form.$error) return;

                // normalize form data
                const data = normalizeFormData(e.target);

                // login
                await this.passwordReset(data);
            },
        },
    };
</script>
